.profileHeader {

  &__top {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 131px;
    padding-top: 15px;
    padding-left: 15px;
    background: $button;
    border-radius: 5px 5px 0 0;

    @include media-breakpoint-up(md) {
      height: 165px;
      padding-bottom: 12px;
      flex-direction: row;
      justify-content: space-between;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 19px;
      height: 240px;
    }

    &__saldo {
      margin-top: auto;
      display: flex;
      flex-direction: row;
      align-items: baseline;

      @include media-breakpoint-up(md) {
        margin-top: 15px;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 30px;
      }

      &__label,
      &__price {
        color: $white;
      }

      &__label {
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }

      &__price {
        font-weight: 600;
        margin-left: 8px;
        white-space: nowrap;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }

    &__logout {
      &__label {
        display: flex;
        align-items: center;
        padding-right: 15px;
        margin-left: auto;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        color: $white;
        order: 2;

        @include media-breakpoint-between(md, lg) {
          padding: 0;
          order: unset;
          margin-bottom: 58px;
          font-size: 16px;
        }

        @include media-breakpoint-up(lg) {
          padding: 0;
          order: unset;
          margin-bottom: 107px;
          font-size: 18px;
        }
      }

      &__icon {
        height: 14px;
        width: 14px;
        margin-right: 8px;
        cursor: pointer;
      }
    }

    &__info {
      display: flex;
      flex-direction: row;

      &__image-wrapper {
        position: relative;
        & .overlay {
          position: absolute;
          display: none;
        }
        &:hover .overlay {
          position: absolute;
          display: flex;
        }

        @include media-breakpoint-only(md) {
          position: absolute;
          height: 108px;
          width: 108px;
          transform: translate(15px, 82px);
        }

        @include media-breakpoint-up(lg) {
          position: absolute;
          height: 162px;
          width: 162px;
          transform: translate(33px, 133px);
        }

        &__upload-icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          stroke-dasharray: 400;
          stroke-dashoffset: 0;
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
          height: 0;
          width: 0;
          animation: dash 5s cubic-bezier(0.4, 0, 1, 1) infinite;
          animation-play-state: paused;

          @keyframes dash {
            to {
              stroke-dashoffset: 1000;
            }
          }
        }
      }

      &__user-image {
        cursor: pointer;
        object-fit: cover;
        border: 2px solid #FFFFFF;
        background-color: #FFFFFF;
        border-radius: 100px;
        height: 54px;
        width: 54px;

        @include media-breakpoint-up(md) {
          height: 108px;
          width: 108px;
          border-width: 4px;
        }

        @include media-breakpoint-up(lg) {
          height: 162px;
          width: 162px;
          border-width: 6px;
        }
      }


      &__data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 8px;

        @include media-breakpoint-up(md) {
          margin-top: auto;
          margin-left: 133px;
          flex-direction: column-reverse;
        }

        @include media-breakpoint-up(lg) {
          margin-left: 225px;
        }

        &__name,
        &__tickets {
          //color: set-button-text-color(toRGB(map_get($colors, "link_color")));
          color: $white;
        }

        &__name {
          font-weight: 600;
          margin-bottom: 3px;

          @include media-breakpoint-only(sm) {
            margin-right: 70px;
          }

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }

        &__tickets {
          margin-bottom: 0;

          @include media-breakpoint-up(lg) {
            font-size: 12px;
          }
        }
      }
    }
  }

  &__bottom {
    padding: 12px 19px;
    background: #FFFFFF;
    box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
    border-radius: 0 0 5px 5px;

    @include media-breakpoint-only(md) {
      padding: 16px 54px 16px 152px;
    }

    @include media-breakpoint-up(lg) {
      padding: 30px 200px 30px 240px;
    }

    @include media-breakpoint-up(xl) {
      padding-right: 350px;
    }

      &__links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
      }
    }

    &__link,
    &__link:link,
    &__link:active,
    &__link:visited {
      color: $main-text;
      text-decoration: none;
      font-size: 12px;
      cursor: pointer;

      transition-duration: 0.1s;
      transition-property: color, font-size;
      transition-timing-function: ease-in;

      @include media-breakpoint-up(md) {
        font-size: 14px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 16px;
      }

      &::after {
        content: ' ';
        width: 0;
        transition-duration: 0.1s;
        transition-property: width;
        transition-timing-function: ease-in;
      }
    }

    &__link:hover,
    &__link.active {
      font-weight: 600;
      color: $title;

      &::after {
        cursor: default;
        content: ' ';
        position: absolute;
        bottom: -50%;
        left: 50%;
        width: 100%;
        height: 2px;
        background-color: $title;
        border-radius: 2px 2px 0 0;
        transform: translate(-50%);

        @include media-breakpoint-up(md) {
          bottom: -65%;
        }

        @include media-breakpoint-up(lg) {
          bottom: -125%;
        }
      }
    }
  }

}

.doc-border {
  border: 1px solid $lines-and-box;
  border-radius: 5px;
  padding: 10px;

  &-no-drag {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
