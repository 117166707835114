.info {
  &-container {
    min-height: 300px;
    height: 100%;
    max-width: 900px;
    margin-top: 40px;
    margin-bottom: 110px;
  }

  &-title {
    font-size: 20px;
    line-height: 22.75px;
    @include media-breakpoint-up(sm) {
      font-size: 30px;
      line-height: 40.23px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 48px;
      line-height: 64px;
    }
    letter-spacing: 0.01em;
    font-family: Montserrat, serif;
    font-weight: 400;
    text-align: center;
  }

  &-subtitle {
    font-family: Montserrat, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: justify;
  }

  &-accordion-container {
    @include media-breakpoint-up(lg) {
      width: 95%;
    }
    margin: auto;
  }

  &-accordion-item {
    border: none;
    border-bottom: 1px solid $lines-and-box;
    padding-bottom: 10px;
    padding-top: 10px;
    &-no-separator {
      border: none;
    }
  }

  &-accordion-button {
    background: none !important;
    border: none transparent !important;
    stroke: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: $title !important;
    font-family: Montserrat, serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;

    &::after {
      background-image: url("../../img/icon/arrows/arrow_bottom.svg") !important;
      background-position: center !important;
    }
  }
  &-accordion-body, {
    font-family: Montserrat, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    & .accordion-link {
      font-family: Montserrat, serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      background: $clickable-links;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
